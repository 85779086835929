import React from "react";
import whychoose from "../Assets/WHY_CHOOSE.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={whychoose} alt="Data" className="ba-image1" />
      </div>

      <div className="ba-text-content">
  <h3 className="ba-title">
    <span>Why Choose Technispark</span>
  </h3>
  <p className="ba-description">
    Discover the reasons to choose Technispark for your data solutions needs. Experience cutting-edge technology, seamless integration, and tailored strategies, making your data management our top priority. Partner with us for optimized data strategies and enhanced business intelligence.
  </p>

  <p className="ba-checks ba-check-first">
    <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Expert Data Solutions
  </p>
  <p className="ba-checks">
    <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Customized Data Strategies
  </p>
  <p className="ba-checks">
    <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Expert in US HealthCare
  </p>
  <p className="ba-checks ba-check-last">
    <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Seamless Integration and Implementation
  </p>
</div>

    </div>
  );
}

export default BookAppointment;
