import React, { useEffect, useRef, useState } from "react";
import InformationCard from "./InformationCard";
import { faChartBar, faDatabase, faCog, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import datavisualization from "../Assets/Data Visulization.png";
import ETL from "../Assets/ETL.png";
import datamigration from "../Assets/Data Migration.png";
import versatile from "../Assets/Versatile Data Solution.png";

function Info() {
  const scrollRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(true);

  const startScrolling = () => {
    if (shouldScroll) {
      scrollIntervalRef.current = setInterval(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });

          // Loop the scroll
          const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
          if (scrollLeft + clientWidth >= scrollWidth) {
            scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
          }
        }
      }, 2000); // Change interval as needed (2000ms = 2 seconds)
    }
  };

  const stopScrolling = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  useEffect(() => {
    // Media query to disable scrolling on smaller screens
    const mediaQuery = window.matchMedia("(max-width: 1000px)");

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setShouldScroll(false);
        stopScrolling();
      } else {
        setShouldScroll(true);
        startScrolling();
      }
    };

    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery); // Check the initial value

    return () => {
      stopScrolling();
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, [shouldScroll]);

  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
        At TechniSpark, we specialize in delivering comprehensive data solutions that transform raw data into actionable insights. From data visualization and reporting to migration, pipelines, and versatile industry-specific solutions, we provide the tools and expertise to help your business harness the full power of its data.
        </p>
      </div>

      <div
        className="info-cards-content"
        ref={scrollRef}
        onMouseEnter={stopScrolling}
        onMouseLeave={shouldScroll ? startScrolling : null}
      >
        {[...Array(2)].map((_, index) => (
          <React.Fragment key={index}>
            <InformationCard
              title="Data Analytics"
              description={[
                "Power BI, Looker Studio",
                "Transform data visually",
                "Easy-to-understand insights",
                "Make data-driven decisions"
              ]}
              icon={faChartBar}
              // imgpath={datavisualization}
              imgpath={datavisualization}
            />

            <InformationCard
              title="Data Migration"
              description={[
                "Accurate data transfer",
                "Minimize system downtime",
                "Maximize data integrity",
                "Focus on your business"
              ]}
              icon={faDatabase}
              imgpath={datamigration}
            />

            <InformationCard
              title="Data Pipeline"
              description={[
                "End-to-end pipeline solutions",
                "Manage data extraction",
                "Process multiple sources",
                "Centralize and integrate data"
              ]}
              icon={faCog}
              imgpath={ETL}
            />

            <InformationCard
              title="Versatile Data Solutions"
              description={[
                "Tailored data solutions",
                "Address unique challenges",
                "Effective data services",
                "Excel across industries"
              ]}
              icon={faPuzzlePiece}
              imgpath={versatile}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Info;
