import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InformationCard({ title, description, icon, imgpath }) {
  return (
    <div className="info-cards">
      {/* <span className="info-card-icon">
        <FontAwesomeIcon className="info-fa-icon" icon={icon} />
      </span> */}
      <div className="info-card-image-div">
          <img
            className="info-card-image"
            src={imgpath}
            alt="services"
          />
        </div>
      <p className="info-card-title">{title}</p>
      <ul className="info-card-description">
        {description.map((desc, index) => (
          <li key={index}>{desc}</li>
        ))}
      </ul>
    </div>
  );
}

export default InformationCard;
