import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse,faGears,faCircleInfo,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import company_logo from "../Assets/Without_BG.png";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      toast.info("Experiencing high traffic, Please wait a moment.", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
        onClose: () => setIsButtonDisabled(false),
      });
    }
  };

  const scrollToInfo = () => {
    const infoSection = document.getElementById("services");
    const navbarHeight = document.querySelector(".navbar-section").offsetHeight; // Get Navbar height
    const scrollPosition = infoSection.offsetTop - navbarHeight; // Calculate the offset

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  const scrollToAbout = () => {
    const infoSection = document.getElementById("about");
    const navbarHeight = document.querySelector(".navbar-section").offsetHeight; // Get Navbar height
    const scrollPosition = infoSection.offsetTop - navbarHeight; // Calculate the offset

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  const scrollToContact = () => {
    const infoSection = document.getElementById("contact");
    const navbarHeight = document.querySelector(".navbar-section").offsetHeight; // Get Navbar height
    const scrollPosition = infoSection.offsetTop - navbarHeight; // Calculate the offset

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/">
          <img className="company_logo" src={company_logo}></img>
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" onClick={scrollToTop} className="navbar-links">
          <FontAwesomeIcon icon={faHouse} />&nbsp;Home
          </Link>
        </li>
        <li>
          <a 
          // href="#services"
           className="navbar-links"
           onClick={scrollToInfo}>
          <FontAwesomeIcon icon={faGears} />&nbsp;Services
          </a>
        </li>
        <li>
          <a 
          // href="#about" 
          className="navbar-links"
          onClick={scrollToAbout}>
          <FontAwesomeIcon icon={faCircleInfo} />&nbsp;About
          </a>
        </li>
        <li>
          <a 
          // href="#contact" 
          className="navbar-links"
          onClick={scrollToContact}>
          <FontAwesomeIcon icon={faEnvelope} />&nbsp;Contact
          </a>
        </li>
      </ul> 

      {/* <button
        className="navbar-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChatBtnClick}
      >
        <FontAwesomeIcon icon={faCommentDots} /> Live Chat
      </button> */}

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
            <FontAwesomeIcon icon={faHouse} />&nbsp;Home
            </Link>
          </li>
          <li>
            <a onClick={() => { scrollToInfo(); openNav(); }}
              // href="#services"
              >
            <FontAwesomeIcon icon={faGears} />&nbsp;Services
            </a>
          </li>
          <li>
            <a onClick={() => { scrollToAbout(); openNav(); }}
            //  href="#about"
             >
            <FontAwesomeIcon icon={faCircleInfo} />&nbsp;About
            </a>
          </li>
          {/* <li>
            <a onClick={openNav} href="#reviews">
              Reviews
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#doctors">
              Doctors
            </a>
          </li> */}
          <li>
            <a onClick={() => { scrollToContact(); openNav(); }} 
            // href="#contact"
            >
            <FontAwesomeIcon icon={faEnvelope} />&nbsp;Contact
            </a>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
