import React, { useState } from "react";
import "../Styles/AppointmentForm.css";
import { toast } from "react-toastify";

function RequestDemoForm({ closeModal }) {
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!clientFirstName.trim()) {
      errors.clientFirstName = "First name is required";
    }

    if (!clientLastName.trim()) {
      errors.clientLastName = "Last name is required";
    }

    if (!clientEmail.trim()) {
      errors.clientEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(clientEmail)) {
      errors.clientEmail = "Email is invalid";
    }

    if (!clientPhoneNumber.trim()) {
      errors.clientPhoneNumber = "Phone number is required";
    } else if (clientPhoneNumber.trim().length !== 10) {
      errors.clientPhoneNumber = "Phone number must be 10 digits";
    }

    if (!meetingDate) {
      errors.meetingDate = "Meeting date is required";
    }
    
    if (!meetingTime) {
      errors.meetingTime = "Meeting time is required";
    }

    if (meetingDate && meetingTime) {
      const selectedDateTime = new Date(`${meetingDate}T${meetingTime}`).getTime();
      const currentTime = new Date().getTime();
      if (selectedDateTime <= currentTime) {
        errors.meetingDateTime = "Please select a future meeting date and time";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Reset form fields and errors after successful submission
    setClientFirstName("");
    setClientLastName("");
    setClientEmail("");
    setClientPhoneNumber("");
    setMeetingDate("");
    setMeetingTime("");
    setFormErrors({});

    toast.success("Demo Requested Successfully!", {
      position: toast.POSITION.TOP_CENTER,
      onOpen: () => setIsSubmitted(true),
      onClose: () => setIsSubmitted(false),
    });

    closeModal();  // Close the modal after submission
  };

  return (
    <div className="form-container">
      <h2 className="form-title">
        <span>Request a Demo</span>
      </h2>

      <form className="form-content" onSubmit={handleSubmit}>
        <div className="form-row">
          <label>
            First Name:
            <input
              type="text"
              value={clientFirstName}
              onChange={(e) => setClientFirstName(e.target.value)}
              required
            />
            {formErrors.clientFirstName && <p className="error-message">{formErrors.clientFirstName}</p>}
          </label>

          <label>
            Last Name:
            <input
              type="text"
              value={clientLastName}
              onChange={(e) => setClientLastName(e.target.value)}
              required
            />
            {formErrors.clientLastName && <p className="error-message">{formErrors.clientLastName}</p>}
          </label>
        </div>

        <div className="form-row">
          <label>
            Email:
            <input
              type="email"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              required
            />
            {formErrors.clientEmail && <p className="error-message">{formErrors.clientEmail}</p>}
          </label>

          <label>
            Phone Number:
            <input
              type="text"
              value={clientPhoneNumber}
              onChange={(e) => setClientPhoneNumber(e.target.value)}
              required
            />
            {formErrors.clientPhoneNumber && <p className="error-message">{formErrors.clientPhoneNumber}</p>}
          </label>
        </div>

        <div className="form-row">
        <label>
          Meeting Date:
          <input
            type="date"
            value={meetingDate}
            onChange={(e) => setMeetingDate(e.target.value)}
            required
          />
          {formErrors.meetingDate && <p className="error-message">{formErrors.meetingDate}</p>}
        </label>

        <label>
          Meeting Time:
          <input
            type="time"
            value={meetingTime}
            onChange={(e) => setMeetingTime(e.target.value)}
            required
          />
          {formErrors.meetingTime && <p className="error-message">{formErrors.meetingTime}</p>}
        </label>
        </div>

        <button type="submit" className="text-appointment-btn-modal">
          Confirm
        </button>

        <p className="success-message" style={{display: isSubmitted ? "block" : "none"}}>Demo details have been sent to the client email.</p>
      </form>
    </div>
  );
}

export default RequestDemoForm;
