import React, { useEffect, useRef } from "react";
import about_us_video from "../Assets/about.mp4"; // Assuming your video file is located in this path
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play();  // Play the video when it comes into view
        } else {
          videoElement.pause();  // Pause the video when it leaves the view
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,  // 50% of the video needs to be visible to start playing
    });

    // Listen for when the video ends and restart it automatically
    const handleVideoEnd = () => {
      videoElement.currentTime = 0; // Reset to the beginning
      videoElement.play(); // Start playing again
    };

    if (videoElement) {
      observer.observe(videoElement);
      videoElement.addEventListener("ended", handleVideoEnd); // Restart video when it ends
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
        videoElement.removeEventListener("ended", handleVideoEnd); // Cleanup the event listener
      }
    };
  }, []);

  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <video
          ref={videoRef}
          src={about_us_video}
          className="about-image1"
          muted  // Optional: Mute the video for autoplay
          autoPlay  // Optional: Auto-plays the video when it's in view
          loop  // Optional: Loops the video
          playsInline  // For mobile browsers, ensures the video plays inline
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Technispark, your trusted partner for innovative data solutions.
          Our expert team offers comprehensive services in analytical dashboard, data management, data warehousing, ETL, and data migration, tailored to meet your unique needs.
          Join us on this journey towards optimized data strategies and enhanced business intelligence.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Choose Your Data Solution"
          description="Find the perfect data solution tailored to your needs at Technispark. Our expert team prioritizes your business objectives, offering customized strategies to drive your success."
        />

        <SolutionStep
          title="Schedule Your Consultation"
          description="Select a convenient date and time, and let our dedicated team guide you through innovative data solutions, ensuring you achieve your business goals with precision."
        />

        <SolutionStep
          title="Unlock Your Data Potential"
          description="Our experienced professionals are here to provide expert advice and personalized strategies, helping you harness the full potential of your data and drive impactful results."
        />
      </div>
    </div>
  );
}

export default About;
