import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import heroimage from "../Assets/heroimage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Hero.css";
import { motion } from "framer-motion";

Modal.setAppElement('#root');

function Hero() {
  const [goUp, setGoUp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const onPageScroll = () => {
      setGoUp(window.scrollY > 600);
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  const animatetext = {
    hidden: { x: -100, opacity: 0 },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        duration: 1.5,
      },
    },
  };

  const animateheroimage = {
    hidden: { x: 100, opacity: 0 },
    show: {
      x: 0, opacity: 1,
      transition: { duration: 1.5 },
    },
  };

  return (
    <div className="section-container">
      <div className="hero-section">
        <motion.div className="text-section" initial="hidden" animate="show" variants={animatetext}>
          <motion.p variants={animatetext} className="text-headline">❤️ Data is the New Oil</motion.p>
          <motion.h2 variants={animatetext} className="text-title">
            Find insights and make decisions.
          </motion.h2>
          <motion.p variants={animatetext} className="text-description">
            Get real-time analytics and data migration solutions. <br />
            On-demand data services at your fingertips. <br />Unlock the full potential of your data within minutes.
          </motion.p>
          <motion.button
            variants={animatetext}
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Request a Demo
          </motion.button>
          <motion.div variants={animatetext} className="text-stats">
            <motion.div variants={animatetext} className="text-stats-container">
              <motion.p variants={animatetext}>8+</motion.p>
              <motion.p variants={animatetext}>Industry Experts</motion.p>
            </motion.div>

            <motion.div variants={animatetext} className="text-stats-container">
              <motion.p variants={animatetext}>6+</motion.p>
              <motion.p variants={animatetext}>Years of Experience</motion.p>
            </motion.div>
          </motion.div>
        </motion.div>

        <div className="hero-image-section">
          <motion.img
            initial="hidden"
            animate="show"
            variants={animateheroimage}
            className="hero-image1"
            src={heroimage}
            alt="company overview"
          />
        </div>
      </div>

      <div onClick={scrollToTop} className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Request a Demo"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <button onClick={closeModal} className="modal-close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdXBgxXPDw8k6izTJmOLBjtyXCBdTfOOILFP4f4aF2TZb8DdQ/viewform?embedded=true"
            width="100%"
            height="100%"
            style={{ border: "0", minHeight: "80vh" }}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Google Form"
            loading="lazy"
          >
            Loading…
          </iframe>
        </div>
      </Modal>
    </div>
  );
}

export default Hero;
